<!--
 * @Author: zhounianlai
 * @Date: 2021-05-27 17:18:50
 * @LastEditTime: 2021-06-07 18:57:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \yue_quanzhan_h5\src\views\localLife\comeOn\h5\conpon\index.vue
-->
<template>
  <div class="content">
    <!--<div class="bg-img-con">-->
    <!--  <img :src="url + imgList[0]">-->
    <!--</div>-->
    <div class="content-img" />
    <div class="content-mian">
      <div class="bg-tip-con bg">
        <!--<div class="conpon-tip"><img :src="url + imgList[0]"></div>-->
      </div>
      <div class="conpon-btn" @click="getPromotionFun">
        <img :src="url + imgList[1]">
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast } from 'vant'
Vue.use(Toast)
import { getPromotion, isDisplay } from '@/services/comeOn.js'
export default {
  data() {
    return {
      url: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/',
      imgList: [
        '2021-09-13/19/yuelvhuieqHaB2vbt61631533764.png',
        '2021-09-13/19/yuelvhuiI3ijxNBkRc1631534040.png'
      ],

      uid: '',
      mobile: ''
    }
  },
  created() {
    const { mid = localStorage.getItem('uid'), mobile } = this.$route.query
    if (mid) {
      this.uid = mid
      this.mobile = mobile
    } else {
      this.uid = ''
      this.mobile = ''
    }

    this.routerAndroidOrWeb()
  },
  methods: {
    // 区别app 和 web
    routerAndroidOrWeb() {
      const { codeNumber } = this.$route.query
      if (this.uid === '' && localStorage.getItem('uid') === null) {
        if (codeNumber === undefined) {
          this.$router.push({
            path: '/login?active=oil'
          })
        } else {
          this.$router.push({
            path: '/login?active=oil&codeNumber=' + codeNumber
          })
        }
      }
      this.shareInfo()
    },

    /**
     * @description 领券接口
     * @author zhounianlai
     * @param {String} mid;// 用户ID
     * @param {String} mobile;// 当前登录人手机号
     */
    getPromotionFun() {
      let params = {}

      if (this.uid) {
        params = {
          mid: this.uid,
          mobile: this.mobile
        }
      } else {
        this.uid = localStorage.getItem('uid')
        this.mobile = localStorage.getItem('mobile')

        params = {
          mid: this.uid,
          mobile: this.mobile
        }
      }

      getPromotion(params)
        .then((res) => {
          this.$router.push({
            path: '/oilConponDetail?',
            query: {
              mid: this.uid,
              mobile: this.mobile,
              codeNumber: localStorage.getItem('recode')
            }
          })
        })
    },
    /**
     * @description 分享
     * @author zhounianlai
     * @param {String} codeNumber;// 邀请码
     */
    shareInfo() {
      let params = {}

      if (this.uid) {
        params = {
          mid: this.uid,
          mobile: this.mobile
        }
      } else {
        this.uid = localStorage.getItem('uid')
        this.mobile = localStorage.getItem('mobile')

        params = {
          mid: this.uid,
          mobile: this.mobile
        }
      }
      isDisplay(params)
        .then((res) => {
          if (res.code === 200) {
            const { title, image, des, url } = res.data
            const shareInfo = {
              title: title,
              desc: des,
              link: url,
              imgUrl: image
            }
            this.$store.dispatch('wxConfigInit', shareInfo)
          }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  padding: 0;
  font-size: 0;
  position: absolute;
  background-color: #E6B1E5;
  //background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/19/yuelvhuieqHaB2vbt61631533764.png")
  //  no-repeat;
  height: 100vh;
  //overflow-y: auto;
  //background-size: 100%;
  .bg-img-con {
    img {
      width: 100%;
      border: 0;
    }
  }
  .content-img {
    background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/19/yuelvhuieqHaB2vbt61631533764.png")
    no-repeat;
    height: 100vh;
    overflow-y: auto;
    background-size: 100%;
  }
  .content-mian {
    position: relative;
    top: -5%;
  }
  //.bg-tip-con {
  //  height: 162px;
  //  background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-05-27/17/yuelvhuiK64PeMa6vu1622106935.png")
  //    no-repeat;
  //}
  //.bg-btn-con {
  //  height: 134px;
  //  background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-05-27/17/yuelvhuiomFxpHe0KY1622106950.png")
  //    no-repeat;
  //}
  .bg {
    background-size: 100%;
    position: relative;
  }
  .conpon-tip,
  .conpon-btn {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  .conpon-tip {
    top: 0;
    img {
      width: 180px;
    }
  }
  .conpon-btn {
    bottom: 26px;
    img {
      width: 240px;
    }
  }
}
</style>
